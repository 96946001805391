import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useGLTF, Float, useCursor, Html } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import * as THREE from 'three';
import { randomNonInt } from '../../Utils/Utils.js';
import { calculateCameraPosition } from '../../Utils/Utils.js';

export const File = (props) => {

    const {
        treeData,
        ID,
        info,
        name,
        viewLevel,
        highestDepth,
        searchResults,
        currentDataRoom,
        showFiles,
        fileType,
        color,
        labelColor,
        cameraRef,
        clickedElement,
        setClickedElement,
        setCameraPosition,
        roots,
        accessResults,
        activityResults,
        themes,
        currentTheme
    } = props;

    const [depthPercentage, setDepthPercentage] = useState(0);
    const [highlight, setHighlight] = useState(false);
    const [hovered, setHover] = useState(false);
    useCursor(hovered);

    const fileRef = useRef();
    const htmlRef = useRef();
    const labelRef = useRef();

    const fileCategories = {
        coding: {
            types: ['.yml', '.yaml', '.json'],
            url: 'https://rvr-beta.s3.amazonaws.com/code.glb'
        },
        compressed: {
            types: ['.zip', '.rar'],
            url: 'https://rvr-beta.s3.amazonaws.com/compressed.glb'
        },
        data: {
            types: ['.dat', '.ndf'],
            url: 'https://rvr-beta.s3.amazonaws.com/data.glb'
        },
        default: {
            types: [],
            url: 'https://rvr-beta.s3.amazonaws.com/default.glb'
        },
        document: {
            types: ['.doc', '.docx'],
            url: 'https://rvr-beta.s3.amazonaws.com/document.glb'
        },
        drawing: {
            types: ['.dwg'],
            url: 'https://rvr-beta.s3.amazonaws.com/drawing.glb'
        },
        email: {
            types: ['.eml'],
            url: 'https://rvr-beta.s3.amazonaws.com/mail.glb'
        },
        excel: {
            types: ['.xlsx', '.xls'],
            url: 'https://rvr-beta.s3.amazonaws.com/excel.glb'
        },
        images: {
            types: ['.jpeg', '.jpg', '.png', '.raw', '.gif', '.svg'],
            url: 'https://rvr-beta.s3.amazonaws.com/image.glb'
        },
        maps: {
            types: ['.kmz', '.kml'],
            url: 'https://rvr-beta.s3.amazonaws.com/maps.glb'
        },
        pdf: {
            types: ['.pdf'],
            url: 'https://rvr-beta.s3.amazonaws.com/pdf.glb'
        },
        powerpoint: {
            types: ['.ppt', '.pptx'],
            url: 'https://rvr-beta.s3.amazonaws.com/powerpoint.glb'
        },
        text: {
            types: ['.txt'],
            url: 'https://rvr-beta.s3.amazonaws.com/txt.glb'
        },
    };

    let url = null;
    for (const [key, value] of Object.entries(fileCategories)) {
        if (value.types.includes(fileType)) url = value.url;
    };
    url = url ? url : fileCategories.default.url
    const { nodes } = useGLTF(url);

    const handleFileClick = useCallback((e) => {

        if (e.type === 'click') {

            if (clickedElement && clickedElement === ID) {
                // setClickedElement(null);
                // setCameraPosition(calculateCameraPosition(roots[name], cameraRef.current));
            } else {
                setClickedElement(ID);
                setCameraPosition(calculateCameraPosition(fileRef.current, cameraRef.current));
            };

        } else if (e.type === 'contextmenu') {

            window.open(info.link, '_blank').focus();

        };

    });

    useEffect(() => {
        let depthPercentage = (1 - ((viewLevel + 1) / (highestDepth[name] + 1)));
        setDepthPercentage(depthPercentage);
    }, [viewLevel, highestDepth, name]);

    useEffect(() => {

        if (searchResults.includes(ID) || accessResults.includes(ID) || activityResults.includes(ID)) setHighlight(true)
        else setHighlight(false);

    }, [searchResults, accessResults, activityResults])

    return (
        // <Float
        //     speed={randomNonInt(1.2)} // Animation speed, defaults to 1
        //     rotationIntensity={randomNonInt(0.015)} // XYZ rotation intensity, defaults to 1
        //     floatIntensity={randomNonInt(0.55)} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
        //     floatingRange={[-0.01, 0.01]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        // >
        <group>
            <Select enabled={hovered}>
                <mesh
                    ref={fileRef}
                    geometry={nodes.Scene.children[0].geometry}
                    // material={nodes.Scene.children[0].material}
                    position={treeData[ID].points[0]}
                    rotation={[Math.PI, 0, 0]}
                    scale={searchResults.includes(ID) ? depthPercentage * 0.02 : depthPercentage * 0.005}
                    onPointerOver={() => { if (name === currentDataRoom) if (!hovered) setHover(true) }}
                    onPointerOut={() => { if (name === currentDataRoom) if (hovered) setHover(false) }}
                    onClick={(e) => { if (name === currentDataRoom) handleFileClick(e) }}
                    onContextMenu={(e) => { if (name === currentDataRoom) handleFileClick(e) }}
                >
                    <meshStandardMaterial
                        color={highlight ? themes[currentTheme].fileHighlight : themes[currentTheme].file}
                        emissive={'#000000'}
                        emissiveIntensity={1}
                        roughness={1}
                        transparent={true}
                        opacity={1}
                    />
                </mesh>
            </Select>
            {hovered && fileRef.current &&
                <Html
                    ref={htmlRef}
                    position={[
                        fileRef.current.position.x,
                        fileRef.current.position.y + 0.01,
                        fileRef.current.position.z
                    ]}
                >
                    <div
                        className="fileInfo"
                        // onPointerOver={() => { if (name === currentDataRoom) setHover(true) }}
                        // onPointerOut={() => { if (name === currentDataRoom) setHover(false) }}
                        onClick={(e) => { if (name === currentDataRoom) handleFileClick(e) }}
                        onContextMenu={(e) => { if (name === currentDataRoom) handleFileClick(e) }}
                    >
                        <p
                            ref={labelRef}
                            style={{
                                fontSize: '10px',
                                color: highlight ? themes[currentTheme].labelHighlight : themes[currentTheme].label,
                                border: `1px solid ${highlight ? themes[currentTheme].labelHighlight : themes[currentTheme].label}`,
                            }}
                        >
                            {treeData[ID].name}
                        </p>
                    </div>
                </Html>
            }
        </group>
        // </Float>
    )
};

// if (url) useGLTF.preload(url);