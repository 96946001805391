import React, { useEffect, useRef, useState } from 'react';
import User from './User';
import UsersIcon from '../../../Icons/UsersIcon';
import MaximizeIcon from '../../../Icons/MaximizeIcon';
import MoveComponentIcon from '../../../Icons/MoveComponentIcon';
import AvatarIcon from '../../../Icons/AvatarIcon';
import Loading from '../../../Icons/Loading';
import axios from "axios";
import { gsap } from "gsap";
import './Users.css';

export default function Users(props) {

    const {
        data,
        showUsers,
        setShowUsers,
        panelOrder,
        setPanelOrder,
        allUsers,
        setAllUsers,
        selectedDataRoom,
        currentDataRoom,
        viewLevel,
        setFilesSelectedUserCanAccess,
        setSocketCommand,
        onlineUsers,
        setCurrentSearch,
        setSearchResults,
        setNumOfMatches,
        componentState,
        setComponentState,
        inspectorWidth,
        rearrange,
        currentAccessActivitySearch,
        setCurrentAccessActivitySearch,
        setAccessResults,
        setActivityResults,
        themes,
        currentTheme
    } = props;

    const [componentPosition, setComponentPosition] = useState(null)
    const [usersToLoad, setUsersToLoad] = useState(null)
    const [hover, setHover] = useState(false)

    const componentRef = useRef();

    const getAllUsers = () => {
        setAllUsers({})
        if (currentDataRoom) setSocketCommand({ type: 'requestDRUserZone', data: [currentDataRoom] })
        else setSocketCommand({ type: 'requestDRUserZone', data: Object.keys(data) })
    };

    useEffect(() => {
        if (viewLevel !== -1) getAllUsers()
        else setUsersToLoad(Object.keys(data))
    }, [])

    useEffect(() => {
        // if (currentDataRoom && !allUsers[currentDataRoom] && viewLevel !== -1) getAllUsers('single')
        // else if (!currentDataRoom && viewLevel === -1) getAllUsers('total')
        // else if (!currentDataRoom && viewLevel === -1) setUsersToLoad(Object.keys(data))
        getAllUsers()
    }, [currentDataRoom])

    useEffect(() => {

        if (!allUsers || !usersToLoad) return;

        let usersToLoadClone = [...usersToLoad]
        usersToLoadClone = usersToLoadClone.filter(elem => !Object.keys(allUsers).includes(elem))
        setUsersToLoad(usersToLoadClone)

    }, [allUsers])

    useEffect(() => {

        if (!usersToLoad) return

        if (usersToLoad.length > 0) {
            setTimeout(() => {
                setSocketCommand({ type: 'requestDRUserZone', data: [usersToLoad[0]] })
            }, 200)
        } else setUsersToLoad(null)

    }, [usersToLoad])

    return (
        <div
            ref={componentRef}
            id="users"
            style={{
                position: componentState.users.beingDragged ? 'absolute' : 'relative',
                top: componentState.users.beingDragged ? `${componentPosition.y}px` : '0px',
                left: componentState.users.beingDragged ? `${componentPosition.x}px` : '0px',
                marginTop: componentState.users.beingDragged && '0px',
                width: rearrange ? `calc(${inspectorWidth}vw - 24px)` : `calc(100% - 24px)`,
                height: componentState.users.maximize ? '500px' : '50px'
            }}
            onClick={(e) => { e.stopPropagation() }}
            onMouseMove={(e) => { e.stopPropagation() }}
        >
            <UsersIcon
                inMenu={false}
                clickable={true}
                panelOrder={panelOrder}
                setPanelOrder={setPanelOrder}
                showUsers={showUsers}
                setShowUsers={setShowUsers}
            />
            <div id="usersInner">
                {Object.keys(allUsers).length && Object.entries(allUsers).map(([key, value]) => {
                    if (key.length) {
                        let online = false
                        if (currentDataRoom) {
                            if (onlineUsers[currentDataRoom].includes(key)) online = true
                        } else {
                            for (const [keyX, valueX] of Object.entries(onlineUsers)) {
                                if (valueX.includes(key)) {
                                    online = true
                                    break
                                }
                            }
                        }
                        return (
                            <div className="user">
                                <AvatarIcon
                                    userOnline={online}
                                />
                                <User
                                    data={data}
                                    user={key}
                                    allUsers={allUsers}
                                    currentDataRoom={currentDataRoom}
                                    setFilesSelectedUserCanAccess={setFilesSelectedUserCanAccess}
                                    setCurrentSearch={setCurrentSearch}
                                    setSearchResults={setSearchResults}
                                    setNumOfMatches={setNumOfMatches}
                                    setSocketCommand={setSocketCommand}
                                    componentState={componentState}
                                    setComponentState={setComponentState}
                                    currentAccessActivitySearch={currentAccessActivitySearch}
                                    setCurrentAccessActivitySearch={setCurrentAccessActivitySearch}
                                    setAccessResults={setAccessResults}
                                    setActivityResults={setActivityResults}
                                    themes={themes}
                                    currentTheme={currentTheme}
                                />
                            </div>
                        )
                    }
                })}
                {!Object.keys(allUsers).length &&
                    <Loading
                        color={themes[currentTheme].altText}
                        size={'24px'}
                    />
                }
            </div>
            <div id="usersStatusBar">
                <MaximizeIcon
                    component={'users'}
                    componentState={componentState}
                    setComponentState={setComponentState}
                />
                <MoveComponentIcon
                    component={'users'}
                    componentRef={componentRef}
                    componentState={componentState}
                    setComponentState={setComponentState}
                    setComponentPosition={setComponentPosition}
                />
                <div className="noOfUsersContainer">
                    <p
                        onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}
                    >
                        {Object.keys(allUsers).length}
                    </p>
                    {hover &&
                        <p className="label">no. of users</p>
                    }
                </div>
            </div>
        </div>
    )
}
