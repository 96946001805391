import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import OrtusLogo from './OrtusLogo';
// import Loading from '../Icons/Loading.js';
import './Pilot.css';

function Pilot(props) {

    const {
        account,
        setAccount,
        setSocketCommand,
        themes,
        currentTheme,
        setApplication
    } = props;

    const [init, setInit] = useState(true);
    const [loggingIn, setLoggingIn] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    const usernameInputRef = useRef();
    const passwordInputRef = useRef();

    const handleLogin = () => {

        if (!usernameInputRef.current.value || !passwordInputRef.current.value) return;

        setLoggingIn(true)
        setAccessDenied(false)

        let url = `https://supacors.herokuapp.com/https://rvrauth.com/check-credentials`;

        let body = new FormData();
        body.append('username', usernameInputRef.current.value);
        body.append('password', passwordInputRef.current.value);
        body.append('ipAddress', '186.8576.976');

        axios.post(url, body)
            .then(function (response) {

                if (response.data.status === 'accessGranted') {
                    setAccount(response.data);
                } else {
                    console.log(response.data.status)
                    setLoggingIn(false)
                    setAccessDenied(true)
                };

            })
            .catch(function (error) {
                console.log('error', error);
                setLoggingIn(false);
            });

    };

    useEffect(() => {
        if (account) {
            const {
                firstName,
                lastName,
                ip,
                sessionId,
                status,
                username
            } = account;
            sessionStorage.setItem('firstName', firstName);
            sessionStorage.setItem('lastName', lastName);
            sessionStorage.setItem('ip', ip);
            sessionStorage.setItem('sessionId', sessionId);
            sessionStorage.setItem('status', status);
            sessionStorage.setItem('username', username);
        };
    }, [account]);

    return (
        <div id="login">
            <div>
                {/* <img src="/logos/pilot_logo_6.svg" onLoad={() => setInit(true)} /> */}
                {init &&
                    <>
                        <AnimatePresence>
                            {!loggingIn && !account &&
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <OrtusLogo
                                        showTitle={true}
                                    />
                                    <form
                                        id="loginButtonContainer"
                                        autoComplete="on"
                                        action=""
                                        noValidate={true}
                                        data-form-type="other"
                                    >
                                        <input
                                            ref={usernameInputRef}
                                            type="email"
                                            name="email"
                                            placeholder="email"
                                            autoComplete="email"
                                        ></input>
                                        <input
                                            ref={passwordInputRef}
                                            type="password"
                                            name="password"
                                            placeholder="password"
                                            autoComplete="current-password"
                                        ></input>
                                        <div
                                            className="loginButton"
                                            onClick={() => handleLogin()}
                                        >
                                            Log in
                                        </div>
                                    </form>
                                </motion.div>
                            }
                        </AnimatePresence>
                        {accessDenied &&
                            <p id="accessDenied">access denied</p>
                        }
                        {/* {loggingIn && !account &&
                    <Loading
                        color={themes[currentTheme].text}
                        size={'24px'}
                    />
                } */}
                        <AnimatePresence>
                            {account &&
                                <motion.div
                                    id="pilotOptions"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <OrtusLogo
                                        showTitle={false}
                                    />
                                    {/* <p id="loggedInUser"><motion.span animate={{ opacity: 0 }} transition={{ ease: "easeOut", duration: 2, delay: 4 }}>Welcome to Pilot </motion.span>{account.firstName}</p> */}
                                    <div id="fileTreeButtonContainer" onClick={() => setApplication('filetree')}>
                                        <img src="./logos/logo2white.svg" />
                                        <Link to="/filetree">Filetree</Link>
                                    </div>
                                    <div id="compassOptions">
                                        <img id="compassLogo" src="/logos/compass_logo_2.svg" />
                                        <div>
                                            <p>Abacus</p>
                                            <div onClick={() => setApplication('compass')}>
                                                <a
                                                    href="https://airtable.com/shr9CbpOOOa6yjz3S"
                                                    target="_blank"
                                                >Data</a>
                                            </div>
                                            <div onClick={() => setApplication('compass')}>
                                                <a
                                                    href="https://airtable.com/invite/l?inviteId=inv00UOaM6ThaAB1q&inviteToken=0752612ad5ed517d49ea92ce492d3f983b4811101c9deaf5d54ddbb12167cec7&utm_medium=email&utm_source=product_team&utm_content=transactional-alerts"
                                                    target="_blank"
                                                >Summary</a>
                                            </div>
                                        </div>
                                        <div>
                                            <p>Ledger</p>
                                            <div onClick={() => setApplication('compass')}>
                                                <a
                                                    href="https://airtable.com/shrfqsVMpvAHyDTUa"
                                                    target="_blank"
                                                >Data</a>
                                            </div>
                                            <div onClick={() => setApplication('compass')}>
                                                <a
                                                    href="https://airtable.com/invite/l?inviteId=invlmpV1tgMwtk5kI&inviteToken=0932928ffde77f363d54ccb066f7e9a0c23dd454dc2bd82f93b708304f831516&utm_medium=email&utm_source=product_team&utm_content=transactional-alerts"
                                                    target="_blank"
                                                >Summary</a>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </>
                }
            </div>
        </div>
    )

};

export default Pilot;