import React, { useEffect, useRef, useState } from 'react';
import { calculatePath } from '../../../Utils/Utils.js';

export default function SearchBar(props) {

    const {
        data,
        treeData,
        valuesOfType,
        setValuesOfType,
        currentSearchTextInput,
        setCurrentSearchTextInput,
        dropDownLimit,
        setDropDownLimit,
        currentSearch,
        setCurrentSearch,
        setSearchResults,
        numOfMatches,
        setNumOfMatches,
        searchType,
        searchCategory,
        location,
        viewLevel,
        setPathToResultItems,
        roots,
        currentDataRoom,
        setCurrentAccessActivitySearch,
        setAccessResults,
        setActivityResults,
        setSocketCommand
    } = props;

    const searchRef = useRef();
    const searchInputRef = useRef();
    const searchInfoRef = useRef();
    const searchClearRef = useRef();

    const selectSearch = (search) => {
        setCurrentSearch(null);
        setTimeout(() => {
            setCurrentSearch(search);
        }, 1);
    };

    const clearSearch = () => {
        setCurrentSearch(null);
        setCurrentSearchTextInput(null);
        setDropDownLimit(10);
        setSearchResults([]);
        setPathToResultItems([]);
        setNumOfMatches(null);
        if (searchType === 'string') {
            searchInputRef.current.value = ''
        };
    };

    const onKeypress = (e) => {
        if (e.code === 'Enter' && searchInputRef.current && searchInputRef.current.value !== '' && searchInputRef.current.value !== currentSearch) {
            // setCurrentSearch(null);
            setCurrentSearch(searchInputRef.current.value);
            // setDropDownLimit(4);
            // handleTextDropDown();
        };
    };

    useEffect(() => {

        if (currentSearch) {
            searchInputRef.current.value = currentSearch
            searchInputRef.current.focus()
        }
        document.addEventListener('keypress', onKeypress);

        return () => {
            document.removeEventListener('keypress', onKeypress);
        };

    }, []);

    useEffect(() => {

        // console.log('values of type', valuesOfType);

    }, [valuesOfType]);

    useEffect(() => {

        if (!currentSearch || !searchCategory.length) return;

        // console.log('current search', currentSearch);
        // console.log('search category', searchCategory);

        let results = [];
        let pathToResultItems = [];

        if (searchCategory.includes('content')) {

            setSocketCommand({ type: 'requestKeyWordResults', data: { keyword: currentSearch.toLowerCase(), dr: currentDataRoom } });

        } else if (viewLevel === -1) {

            for (const [key, val] of Object.entries(data)) {

                for (const elem of searchCategory) {

                    let arr = Object.entries(val).filter(([keyX, valX]) => valX[elem] && valX[elem].toLowerCase().includes(currentSearch.toLowerCase()));
                    arr = arr.map(elemX => elemX[0]);
                    results = results.concat(arr);

                    for (const result of arr) {
                        let path = calculatePath(result, val, roots[key].ID);
                        pathToResultItems = pathToResultItems.concat(path);
                    };

                };

            };

        } else {

            for (const elem of searchCategory) {

                let arr = Object.entries(treeData).filter(([key, val]) => val[elem] && val[elem].toLowerCase().includes(currentSearch.toLowerCase()));
                arr = arr.map(elemX => elemX[0]);
                results = results.concat(arr);

            }

            for (const result of results) {
                let path = calculatePath(result, treeData, roots[currentDataRoom].ID);
                pathToResultItems = pathToResultItems.concat(path);
            }

        }

        if (currentSearch !== '*') setNumOfMatches(results.length)
        setSearchResults(results)
        setPathToResultItems(pathToResultItems)
        setCurrentAccessActivitySearch(null)
        setAccessResults([])
        setActivityResults([])

    }, [currentSearch]);

    useEffect(() => {

        if (!treeData) return;

        let content = [];
        for (const elem of searchCategory) {
            if (elem !== 'content') {
                let arr = [...new Set(Object.entries(treeData).map(([key, val]) => val[elem]))];
                content = content.concat(arr);
            }
        };

        if (currentSearchTextInput) content = content.filter(elem => elem.toLowerCase().includes(currentSearchTextInput.toLowerCase()));
        setValuesOfType(content);

    }, [currentSearchTextInput, searchCategory, treeData]);

    return (
        <div id={location === 'menu' ? "searchBarContainerMenu" : "searchBarContainerInspector"}>
            <div id="searchText">
                {location === 'inspector' &&
                    <div id="textDropDown">
                        {valuesOfType && currentSearchTextInput && valuesOfType.slice(0, dropDownLimit).map((elem, i, arr) => {
                            if ((!currentSearchTextInput || valuesOfType.length)) {
                                return <p
                                    className="textDropDownOptions"
                                    onClick={() => selectSearch(elem)}
                                    key={elem}
                                >{elem.length <= 40 ? elem : elem.substring(0, 40) + '...'}</p>
                            }
                        })}
                    </div>
                }
                <input ref={searchInputRef} id="searchTextInput" type="text" placeholder={location === 'menu' ? 'search' : null} onChange={(e) => setCurrentSearchTextInput(e.target.value)} />
            </div>
            <div ref={searchInfoRef} id="searchInfo">
                {(numOfMatches || numOfMatches === 0) &&
                    <div ref={searchClearRef} id="searchClear" onClick={() => clearSearch()}>x</div>
                }
                {(numOfMatches || numOfMatches === 0) &&
                    <p>{numOfMatches + ' matches'}</p>
                }
            </div>
        </div>
    )
}
