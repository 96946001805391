import React, { useState, useEffect } from 'react';
import { Routes, Route, Redirect } from "react-router-dom";
import Pilot from './Pilot/Pilot';
// import Compass from './Compass/Compass';
import Filetree from './Filetree/Filetree';

function App() {

    // Account
    let loggedInUser = {
        firstName: sessionStorage.getItem('firstName'),
        lastName: sessionStorage.getItem('lastName'),
        ip: sessionStorage.getItem('ip'),
        sessionId: sessionStorage.getItem('sessionId'),
        status: sessionStorage.getItem('status'),
        username: sessionStorage.getItem('username'),
    };
    const [account, setAccount] = useState(loggedInUser.username ? loggedInUser : false);
    const [application, setApplication] = useState('pilot');

    return (
        <div className="app">
            <Routes>

                <Route
                    path="/"
                    element={
                        <Pilot
                            account={account}
                            setAccount={setAccount}
                            setApplication={setApplication}
                        />
                    }
                />

                {/* <Route
                    path="compass"
                    element={<Compass />}
                /> */}

                <Route
                    path="filetree"
                    element={
                        <Filetree
                            account={account}
                            setAccount={setAccount}
                            application={application}
                        />
                    }
                />

                <Route
                    path="*"
                    element={<Pilot />}
                />

            </Routes>
        </div>
    )

};

export default App;