import React, { useEffect, useRef, useState, memo, useCallback } from 'react';
import { useCursor, Html } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import * as THREE from 'three';
import { calculateCameraPosition } from '../../Utils/Utils.js';

export function Branch(props) {

    const {
        data,
        treeData,
        ID,
        info,
        name,
        currentDataRoom,
        setCurrentDataRoom,
        highestDepth,
        clickedElement,
        setClickedElement,
        highlightedPath,
        searchResults,
        setCameraPosition,
        cameraRef,
        currentZone,
        showBranchLabels,
        treePositions,
        setTreePositions,
        viewLevel,
        currentTheme,
        themes,
        roots,
        setRoots,
        showFiles,
        updateTree,
        accessResults,
        activityResults,
        updatedItems,
        setUpdatedItems,
        setViewLevel,
    } = props;

    const [hovered, setHover] = useState(false);
    const [highlight, setHighlight] = useState(false);

    const branchRef = useRef();
    const htmlRef = useRef();
    const labelRef = useRef();

    useCursor(hovered);

    const handleBranchClick = useCallback((e) => {

        if (e.type === 'click') {

            if (clickedElement && clickedElement === ID) {
                // setClickedElement(null);
                // setCameraPosition(calculateCameraPosition(roots[name], cameraRef.current));
            } else {
                setClickedElement(ID)
                setCameraPosition(calculateCameraPosition(branchRef.current, cameraRef.current))
            };

        } else if (e.type === 'contextmenu') {

            window.open(info.link, '_blank').focus();

        };

    });

    const dot = (v1, v2) => {
        return v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2];
    };

    const cross = (v1, v2) => {
        return [v1[1] * v2[2] - v1[2] * v2[1], v1[2] * v2[0] - v1[0] * v2[2], v1[0] * v2[1] - v1[1] * v2[0]];
    };

    const length = (v) => {
        return Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
    };

    const scaleVec = (v, s) => {
        return [v[0] * s, v[1] * s, v[2] * s];
    };

    const subVec = (v1, v2) => {
        return [v1[0] - v2[0], v1[1] - v2[1], v1[2] - v2[2]];
    };

    const addVec = (v1, v2) => {
        return [v1[0] + v2[0], v1[1] + v2[1], v1[2] + v2[2]];
    };

    const vecAxisAngle = (vec, axis, angle) => {
        var cosr = Math.cos(angle);
        var sinr = Math.sin(angle);
        return addVec(addVec(scaleVec(vec, cosr), scaleVec(cross(axis, vec), sinr)), scaleVec(axis, dot(axis, vec) * (1 - cosr)));
    };

    const normalize = (v) => {
        var l = length(v);
        return scaleVec(v, 1 / l);
    };

    let verts = [];
    let faces = [];
    let normals = [];
    let colours = [];
    let segments = 8;
    let segmentAngle = Math.PI * 2 / segments;
    let points = info.points;
    let thickness = info.thickness;
    let numOfSections = thickness / (points.length);

    for (let i = 0; i < points.length; i++) {

        let axis;
        if (i === points.length - 1) {
            axis = normalize(subVec(points[i], points[i - 1]))
        } else {
            axis = normalize(subVec(points[i + 1], points[i]))
        };

        let normal = normalize(cross(axis, [axis[2], axis[0], axis[1]]));
        let tangent = normalize(cross(axis, normal));
        let radius = thickness - (i * numOfSections);

        for (let j = 0; j < segments + 1; j++) {
            let vec = vecAxisAngle(tangent, axis, -segmentAngle * j);
            let vert = addVec(points[i], scaleVec(vec, radius));
            verts.push(vert[0], vert[1], vert[2]);

            let norm = normalize(cross(axis, vec))
            normals.push(norm[0], norm[1], norm[2]);

            const r = (i / 8) + 0.5;
            const g = (j / 8) + 0.5;
            colours.push(r, g, 1);
        };

    };

    verts = Float32Array.from(verts);
    normals = Float32Array.from(normals);
    colours = Float32Array.from(colours);

    // faces ====================

    for (let i = 0; i < points.length - 1; i++) {

        for (let j = 0; j < segments; j++) {

            const a = i * (segments + 1) + (j + 1);
            const b = i * (segments + 1) + j;
            const c = (i + 1) * (segments + 1) + j;
            const d = (i + 1) * (segments + 1) + (j + 1);

            faces.push(a, b, d);
            faces.push(b, c, d);

        };

    };

    faces = new Uint32Array(faces);

    useEffect(() => {

        if (branchRef.current.root) {

            let rootsClone = { ...roots };
            rootsClone[name] = branchRef.current;
            setRoots(rootsClone);

        };

    }, []);

    useEffect(() => {

        if (searchResults.includes(ID) || accessResults.includes(ID) || activityResults.includes(ID)) setHighlight(true)
        else setHighlight(false);

    }, [searchResults, accessResults, activityResults])

    useEffect(() => {

        if (clickedElement && clickedElement === ID && updatedItems.includes(ID)) {
            console.log('made it');
            setClickedElement(null)
            setTimeout(() => {
                setClickedElement(ID)
                setCameraPosition(calculateCameraPosition(branchRef.current, cameraRef.current))
                setUpdatedItems([])
            }, 1)
        }

    }, updatedItems, clickedElement)

    return (
        <group>
            <Select enabled={hovered}>
                <mesh
                    key={ID}
                    ID={ID}
                    ref={branchRef}
                    root={ID === info.parent ? true : false}
                    onPointerOver={(e) => { if (name === currentDataRoom) {e.stopPropagation(); setHover(true)} }}
                    onPointerOut={(e) => { if (name === currentDataRoom) {e.stopPropagation(); setHover(false)} }}
                    onClick={(e) => { if (name === currentDataRoom) {e.stopPropagation(); handleBranchClick(e)} }}
                    onContextMenu={(e) => { if (name === currentDataRoom) {e.stopPropagation(); handleBranchClick(e)} }}
                    receiveShadow
                    castShadow
                >
                    <bufferGeometry attach="geometry">
                        <bufferAttribute
                            array={faces}
                            attach="index"
                            count={faces.length}
                            itemSize={1}
                        />
                        <bufferAttribute
                            attach="attributes-position"
                            count={verts.length / 3}
                            array={verts}
                            itemSize={3}
                        />
                        <bufferAttribute
                            attach="attributes-color"
                            count={colours.length / 3}
                            array={colours}
                            itemSize={3}
                        />
                        <bufferAttribute
                            attach="attributes-normal"
                            count={normals.length / 3}
                            array={normals}
                            itemSize={3}
                        />
                    </bufferGeometry>
                    <meshStandardMaterial
                        color={highlight ? themes[currentTheme].search : highlightedPath.includes(ID) ? themes[currentTheme].treeHighlight : themes[currentTheme].tree}
                        emissive={'#000000'}
                        emissiveIntensity={1}
                        roughness={1}
                        transparent={true}
                        opacity={info.totalNumberOfDescendants > 0 || searchResults.includes(ID) ? 1 : 0.5}
                    />
                </mesh >
                {
                    (!currentZone || info.zone === currentZone || (currentZone === 'root' && viewLevel !== -1)) &&
                    ((showBranchLabels && (
                        (viewLevel === -1 && (branchRef.current && branchRef.current.root)) ||
                        (viewLevel > -1 && treeData[ID].depth <= viewLevel + 1))) ||
                        (!showBranchLabels && branchRef.current.root) ||
                        searchResults.includes(ID)
                    ) &&
                    <Html
                        ref={htmlRef}
                        position={[
                            points[points.length - 1][0],
                            points[points.length - 1][1],
                            points[points.length - 1][2]
                        ]}
                    >
                        <div
                            className="fileInfo"
                            onPointerOver={() => { if (name === currentDataRoom) setHover(true) }}
                            onPointerOut={() => { if (name === currentDataRoom) setHover(false) }}
                            onClick={(e) => {
                                if (name === currentDataRoom) handleBranchClick(e)
                                else if (!currentDataRoom) {
                                    setCurrentDataRoom(name)
                                    setClickedElement(null)
                                    setViewLevel(0)
                                }
                            }}
                            onContextMenu={(e) => {
                                if (name === currentDataRoom || !currentDataRoom) handleBranchClick(e)
                            }}
                            style={{
                                opacity: info.totalNumberOfDescendants === 0 ? '0.5' : '1'
                            }}
                        >
                            <p
                                ref={labelRef}
                                style={{
                                    color: highlight ? themes[currentTheme].labelHighlight : themes[currentTheme].label,
                                    border: `1px solid ${highlight ? themes[currentTheme].labelHighlight : themes[currentTheme].label}`,
                                    transform: hovered ? 'scale(1.3)' : 'scale(1)'
                                }}
                            >
                                {info.name}
                            </p>
                        </div>
                    </Html>
                }
            </Select>
        </group>
    )

};