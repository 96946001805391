import React, { useEffect, useState } from 'react';
import { useCursor } from '@react-three/drei';
import { Selection, Select, EffectComposer, Outline } from '@react-three/postprocessing'
import * as THREE from 'three';
import UserAvatars from './UserAvatars';

export function Base(props) {

    const {
        name,
        currentDataRoom,
        setCurrentDataRoom,
        setClickedElement,
        setViewLevel,
        onlineUsers,
        themes,
        currentTheme,
        viewLevel
    } = props;

    const [hovered, setHover] = useState(false);
    useCursor(hovered);

    const handleGroundClick = () => {
        if (name !== currentDataRoom) {
            setCurrentDataRoom(name);
            setClickedElement(null);
            setViewLevel(0);
        };
    };

    useEffect(() => {
        // console.log('user in data room', onlineUsers[name]);
    }, [onlineUsers])

    return (
        <group>
            {/* <Selection> */}
                {/* <EffectComposer
                    multisampling={8}
                    autoClear={false}
                > */}
                    {/* <Outline
                        visibleEdgeColor={themes[currentTheme].outline}
                        edgeStrength={100}
                        width={1000}
                    /> */}
                {/* </EffectComposer> */}
                <Select
                    enabled={hovered}
                >
                    <mesh
                        rotation={[Math.PI / -2, 0, 0]}
                        receiveShadow
                        castShadow
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        onClick={() => handleGroundClick()}
                    >
                        <planeGeometry args={[8, 8, 1, 1]} />
                        <meshBasicMaterial
                            side={THREE.DoubleSide}
                            wireframe={false}
                            transparent={true}
                            opacity={0}
                        />
                    </mesh>

                </Select>
            {/* </Selection> */}

            {/* <UserAvatars
                name={name}
                themes={themes}
                currentTheme={currentTheme}
                viewLevel={viewLevel}
                onlineUsers={onlineUsers}
            /> */}

        </group>

    )

};