import React, { useState, useEffect } from 'react';
// import Login from "./Dashboard/Login.js";
import Menu from "./Dashboard/Menu.js";
import Inspector from "./Dashboard/Inspector/Inspector.js";
import MainVisual from "./Visual/MainVisual.js";
import Background from "./Background/Background.js";
import Socket from "./Socket/Socket.js";

function Filetree(props) {

    const {
        account,
        setAccount,
        application
    } = props;

    //Socket
    const [socketStatus, setSocketStatus] = useState('close');
    const [socketCommand, setSocketCommand] = useState(null);

    //Mode
    const [mode, setMode] = useState('prod');

    // Home
    const [home, setHome] = useState(true)

    // Data
    const [data, setData] = useState({})
    const [selectedDataRoom, setSelectedDataRoom] = useState(null)
    const [currentDataRoom, setCurrentDataRoom] = useState(null)
    const [treePositions, setTreePositions] = useState({})
    const [availableDataRooms, setAvailableDataRooms] = useState(null)
    const [availableDataRoomGroups, setAvailableDataRoomGroups] = useState(null)
    const [onlineUsers, setOnlineUsers] = useState({})
    const [updateTree, setUpdateTree] = useState(false)
    const [showAvailableDataRooms, setShowAvailableDataRooms] = useState(true)
    const [airTableData, setAirTableData] = useState({})

    // Loading
    const [loading, setLoading] = useState(false);

    // Built
    const [built, setBuilt] = useState(false);

    // Dashboard
    const [dashboardVisible, setDashboardVisible] = useState(false);

    // Visual
    const [roots, setRoots] = useState({});
    const [highestDepth, setHighestDepth] = useState({});

    // Camera
    const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 50, z: 10, d: 0 });
    const [viewLevel, setViewLevel] = useState(0);
    const [camera, setCamera] = useState(null);
    const [overview, setOverview] = useState(false);

    //Inspector
    const [showFileInspector, setShowFileInspector] = useState(false);
    const [inspectorWidth, setInspectorWidth] = useState(30);

    //Extra Panels
    const [panelOrder, setPanelOrder] = useState([]);

    // Folder Contents
    const [showFolderContents, setShowFolderContents] = useState(false);

    // Updater
    const [updaterStatus, setUpdaterStatus] = useState(null);
    const [updatedItems, setUpdatedItems] = useState([]);

    // Users
    const [showUsers, setShowUsers] = useState(false);
    const [allUsers, setAllUsers] = useState({})
    // const [allUsers, setAllUsers] = useState({});
    const [filesSelectedUserCanAccess, setFilesSelectedUserCanAccess] = useState(null);

    // Search
    const [showSearch, setShowSearch] = useState(false);
    const [searchableValues, setSearchableValues] = useState(null);
    const [searchCategory, setSearchCategory] = useState(['name']);
    const [currentSearch, setCurrentSearch] = useState(null);
    const [numOfMatches, setNumOfMatches] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [pathToResultItems, setPathToResultItems] = useState([]);
    const [valuesOfType, setValuesOfType] = useState(null);
    const [currentSearchTextInput, setCurrentSearchTextInput] = useState(null);
    const [dropDownLimit, setDropDownLimit] = useState(10);
    const [searchType, setSearchType] = useState('string');

    // Activity / Access
    const [accessResults, setAccessResults] = useState([]);
    const [activityResults, setActivityResults] = useState([]);
    const [currentAccessActivitySearch, setCurrentAccessActivitySearch] = useState(null);

    // Mini Map
    const [scene, setScene] = useState(null);
    const [mapCamera, setMapCamera] = useState(null);
    const [mapMaximize, setMapMaximize] = useState(false);
    const [mapResize, setMapResize] = useState({ resize: false, width: null, height: null });
    // const [mapResize, setMapResize] = useState(false);
    const [showMiniMap, setShowMiniMap] = useState(false);
    const [miniMapMarker, setMiniMapMarker] = useState({ x: 0, y: 100, z: 0 });

    // Element Info
    const [clickedElement, setClickedElement] = useState(null);
    const [highlightedPath, setHighlightedPath] = useState([]);
    const [filesToInspect, setFilesToInspect] = useState([]);

    // Background
    const [backgroundPosition, setBackgroundPosition] = useState(null);

    // Settings
    const [showSettings, setShowSettings] = useState(false);
    const [settings, setSettings] = useState({
        information: {
            open: false,
            path: {
                open: false,
                selected: [],
                options: [],
                add: {
                    open: false
                }
            },
            reveal_level: {
                open: false,
                current_depth_plus: {
                    open: false,
                    selected: 1,
                    options: [1, 2, 3, 4, 5, 6, 7, 8]
                }
            },
        }
    });
    const [showBranchLabels, setShowBranchLabels] = useState(true);
    const [showFiles, setShowFiles] = useState(true);
    const [revealThreshold, setRevealThreshold] = useState({});
    const [colourBy, setColourBy] = useState(null);
    const [orbitControls, setOrbitControls] = useState(false);

    // Themes
    const [currentTheme, setCurrentTheme] = useState('meg');
    const [themes, setThemes] = useState({
        night: {
            one: '#000000',
            background: '#202030',
            ground: '#ffffff',
            mesh: '#ffffff',
            avatar: '#ffffff',
            treeHighlight: '#69625d',
            tree: '#fbaf00',
            search: '#ff8600',
        },
        fire: {
            one: '#000000',
            background: '#000000',
            ground: '#ff0000',
            mesh: '#ff0000',
            avatar: '#ff0000',
            treeHighlight: '#69625d',
            tree: '#ff0000',
            search: '#ff8600',
        },
        tech: {
            one: '#000000',
            background: '#000000',
            ground: 'lime',
            mesh: 'lime',
            avatar: 'lime',
            treeHighlight: '#69625d',
            tree: 'lime',
            search: '#ff8600',
        },
        cute: {
            one: '#191716',
            treeHighlight: '#F2CCC3',
            background: '#0000a2',
            ground: '#00FFFF',
            mesh: '#00FFFF',
            avatar: '#00FFFF',
            tree: '#FF00C8',
            search: '#ff8600',
        },
        meg: {
            // visual
            background: '#ffffff',
            ground: '#6691d1',
            // ground: '#e1dcd5',
            mesh: '#ffffff',
            // mesh: '#3c80e8',
            tree: '#3c80e8',
            treeHighlight: '#949494',
            file: '#0058ef',
            fileHighlight: '#ff8600',
            label: '#000000',
            labelHighlight: '#0058ef',
            search: '#ff8600',
            avatar: '#ff8600',
            outline: '#ff8600',
            // dashboard
            icon: '#000000',
            altIcon: '#ffffff',
            text: '#000000',
            altText: '#ffffff',
            border: '#000000',
            altBorder: '#ffffff',
            dashBackground: '#ffffff40',
            altDashBackground: '#0058efa0',
            userOnline: '#ff8600'
        },
    });


    useEffect(() => {
        window.addEventListener('contextmenu', (e) => { e.preventDefault() });
        document.documentElement.style.setProperty('--icon-color', themes[currentTheme].icon);
        document.documentElement.style.setProperty('--alt-icon-color', themes[currentTheme].altIcon);
        document.documentElement.style.setProperty('--text-color', themes[currentTheme].text);
        document.documentElement.style.setProperty('--alt-text-color', themes[currentTheme].altText);
        document.documentElement.style.setProperty('--border-color', themes[currentTheme].border);
        document.documentElement.style.setProperty('--alt-border-color', themes[currentTheme].altBorder);
        document.documentElement.style.setProperty('--dash-background-color', themes[currentTheme].dashBackground);
        document.documentElement.style.setProperty('--alt-dash-background-color', themes[currentTheme].altDashBackground);
        document.documentElement.style.setProperty('--user-online-color', themes[currentTheme].userOnline);
        document.documentElement.style.setProperty('--file-highlight-color', themes[currentTheme].fileHighlight);
    }, []);

    useEffect(() => {
        if (Object.keys(data).length) console.log('data', data);
    }, [data])

    useEffect(() => {
        // console.log('!!!socket status!!!', socketStatus);
    }, [socketStatus])

    useEffect(() => {

        // console.log('home', home);

        if (home) {
            // setDashboardVisible(false)
            setData({})
            setRoots({})
            setPanelOrder([])
            setCurrentDataRoom(null)
            setCameraPosition({ x: 0, y: 50, z: 10, d: 2 })
            setTimeout(() => {
                setShowAvailableDataRooms(true)
            }, 2010)
        }

    }, [home])

    return (
        <>
            <Socket
                data={data}
                setData={setData}
                settings={settings}
                setSettings={setSettings}
                account={account}
                setAccount={setAccount}
                setSocketStatus={setSocketStatus}
                socketCommand={socketCommand}
                setSocketCommand={setSocketCommand}
                setAvailableDataRooms={setAvailableDataRooms}
                setAvailableDataRoomGroups={setAvailableDataRoomGroups}
                selectedDataRoom={selectedDataRoom}
                setSelectedDataRoom={setSelectedDataRoom}
                setCurrentDataRoom={setCurrentDataRoom}
                setSearchableValues={setSearchableValues}
                loading={loading}
                setLoading={setLoading}
                highestDepth={highestDepth}
                setHighestDepth={setHighestDepth}
                onlineUsers={onlineUsers}
                setOnlineUsers={setOnlineUsers}
                allUsers={allUsers}
                setAllUsers={setAllUsers}
                revealThreshold={revealThreshold}
                setRevealThreshold={setRevealThreshold}
                setUpdateTree={setUpdateTree}
                setUpdaterStatus={setUpdaterStatus}
                themes={themes}
                currentTheme={currentTheme}
                roots={roots}
                setNumOfMatches={setNumOfMatches}
                setSearchResults={setSearchResults}
                setPathToResultItems={setPathToResultItems}
                setCurrentAccessActivitySearch={setCurrentAccessActivitySearch}
                setAccessResults={setAccessResults}
                setActivityResults={setActivityResults}
                setUpdatedItems={setUpdatedItems}
                mode={mode}
                home={home}
                airTableData={airTableData}
                setAirTableData={setAirTableData}
            />
            {/* {application === 'pilot' &&
                <Login
                    account={account}
                    setAccount={setAccount}
                    setSocketCommand={setSocketCommand}
                    themes={themes}
                    currentTheme={currentTheme}
                    setApplication={setApplication}
                />
            } */}
            {/* {application === 'compass' &&
                <div>compass</div>
            } */}
            {application === 'filetree' &&
                <>
                    <Menu
                        data={data}
                        setData={setData}
                        currentDataRoom={currentDataRoom}
                        setCurrentDataRoom={setCurrentDataRoom}
                        treeData={data[currentDataRoom]}
                        account={account}
                        availableDataRooms={availableDataRooms}
                        availableDataRoomGroups={availableDataRoomGroups}
                        built={built}
                        setSearchableValues={setSearchableValues}
                        dashboardVisible={dashboardVisible}
                        showSearch={showSearch}
                        setShowSearch={setShowSearch}
                        showMiniMap={showMiniMap}
                        setShowMiniMap={setShowMiniMap}
                        loading={loading}
                        setLoading={setLoading}
                        showSettings={showSettings}
                        setShowSettings={setShowSettings}
                        settings={settings}
                        setSettings={setSettings}
                        cameraPosition={cameraPosition}
                        viewLevel={viewLevel}
                        setViewLevel={setViewLevel}
                        highestDepth={highestDepth}
                        setHighestDepth={setHighestDepth}
                        panelOrder={panelOrder}
                        setPanelOrder={setPanelOrder}
                        showFolderContents={showFolderContents}
                        setShowFolderContents={setShowFolderContents}
                        showUsers={showUsers}
                        setShowUsers={setShowUsers}
                        inspectorWidth={inspectorWidth}
                        setInspectorWidth={setInspectorWidth}
                        selectedDataRoom={selectedDataRoom}
                        setSelectedDataRoom={setSelectedDataRoom}
                        setCameraPosition={setCameraPosition}
                        clickedElement={clickedElement}
                        setClickedElement={setClickedElement}
                        filesToInspect={filesToInspect}
                        setSocketCommand={setSocketCommand}
                        roots={roots}
                        setRoots={setRoots}
                        valuesOfType={valuesOfType}
                        setValuesOfType={setValuesOfType}
                        currentSearchTextInput={currentSearchTextInput}
                        setCurrentSearchTextInput={setCurrentSearchTextInput}
                        dropDownLimit={dropDownLimit}
                        setDropDownLimit={setDropDownLimit}
                        currentSearch={currentSearch}
                        setCurrentSearch={setCurrentSearch}
                        setSearchResults={setSearchResults}
                        numOfMatches={numOfMatches}
                        setNumOfMatches={setNumOfMatches}
                        searchType={searchType}
                        searchCategory={searchCategory}
                        onlineUsers={onlineUsers}
                        setPathToResultItems={setPathToResultItems}
                        updaterStatus={updaterStatus}
                        setUpdaterStatus={setUpdaterStatus}
                        themes={themes}
                        currentTheme={currentTheme}
                        setCurrentAccessActivitySearch={setCurrentAccessActivitySearch}
                        setAccessResults={setAccessResults}
                        setActivityResults={setActivityResults}
                        setOverview={setOverview}
                        socketStatus={socketStatus}
                        home={home}
                        setHome={setHome}
                        showAvailableDataRooms={showAvailableDataRooms}
                        setShowAvailableDataRooms={setShowAvailableDataRooms}
                    />
                    <Inspector
                        data={data}
                        treeData={data[currentDataRoom]}
                        currentDataRoom={currentDataRoom}
                        filesToInspect={filesToInspect}
                        highlightedPath={highlightedPath}
                        settings={settings}
                        searchCategory={searchCategory}
                        setSearchCategory={setSearchCategory}
                        searchableValues={searchableValues}
                        currentSearch={currentSearch}
                        setCurrentSearch={setCurrentSearch}
                        numOfMatches={numOfMatches}
                        setNumOfMatches={setNumOfMatches}
                        showFileInspector={showFileInspector}
                        showSearch={showSearch}
                        setShowSearch={setShowSearch}
                        setSearchResults={setSearchResults}
                        panelOrder={panelOrder}
                        setPanelOrder={setPanelOrder}
                        showSettings={showSettings}
                        setShowSettings={setShowSettings}
                        setSettings={setSettings}
                        setSearchableValues={setSearchableValues}
                        revealThreshold={revealThreshold}
                        setRevealThreshold={setRevealThreshold}
                        colourBy={colourBy}
                        setColourBy={setColourBy}
                        showFolderContents={showFolderContents}
                        setShowFolderContents={setShowFolderContents}
                        showUsers={showUsers}
                        setShowUsers={setShowUsers}
                        scene={scene}
                        mapCamera={mapCamera}
                        mapMaximize={mapMaximize}
                        setMapMaximize={setMapMaximize}
                        setMapResize={setMapResize}
                        inspectorWidth={inspectorWidth}
                        showBranchLabels={showBranchLabels}
                        setShowBranchLabels={setShowBranchLabels}
                        allUsers={allUsers}
                        setAllUsers={setAllUsers}
                        selectedDataRoom={selectedDataRoom}
                        setFilesSelectedUserCanAccess={setFilesSelectedUserCanAccess}
                        viewLevel={viewLevel}
                        valuesOfType={valuesOfType}
                        setValuesOfType={setValuesOfType}
                        currentSearchTextInput={currentSearchTextInput}
                        setCurrentSearchTextInput={setCurrentSearchTextInput}
                        dropDownLimit={dropDownLimit}
                        setDropDownLimit={setDropDownLimit}
                        searchType={searchType}
                        currentTheme={currentTheme}
                        setCurrentTheme={setCurrentTheme}
                        themes={themes}
                        highestDepth={highestDepth}
                        setSocketCommand={setSocketCommand}
                        showFiles={showFiles}
                        setShowFiles={setShowFiles}
                        setPathToResultItems={setPathToResultItems}
                        roots={roots}
                        onlineUsers={onlineUsers}
                        searchResults={searchResults}
                        currentAccessActivitySearch={currentAccessActivitySearch}
                        setCurrentAccessActivitySearch={setCurrentAccessActivitySearch}
                        setAccessResults={setAccessResults}
                        setActivityResults={setActivityResults}
                        clickedElement={clickedElement}
                        setClickedElement={setClickedElement}
                        setCameraPosition={setCameraPosition}
                        camera={camera}
                        socketStatus={socketStatus}
                    />
                    <MainVisual
                        data={data}
                        setData={setData}
                        currentDataRoom={currentDataRoom}
                        setCurrentDataRoom={setCurrentDataRoom}
                        // treeData={data[currentDataRoom]}
                        searchableValues={searchableValues}
                        built={built}
                        setBuilt={setBuilt}
                        dashboardVisible={dashboardVisible}
                        setDashboardVisible={setDashboardVisible}
                        setBackgroundPosition={setBackgroundPosition}
                        highlightedPath={highlightedPath}
                        setHighlightedPath={setHighlightedPath}
                        currentSearch={currentSearch}
                        setNumOfMatches={setNumOfMatches}
                        searchCategory={searchCategory}
                        showMiniMap={showMiniMap}
                        setShowMiniMap={setShowMiniMap}
                        miniMapMarker={miniMapMarker}
                        setMiniMapMarker={setMiniMapMarker}
                        settings={settings}
                        revealThreshold={revealThreshold}
                        colourBy={colourBy}
                        setShowFileInspector={setShowFileInspector}
                        setFilesToInspect={setFilesToInspect}
                        setCamera={setCamera}
                        cameraPosition={cameraPosition}
                        setCameraPosition={setCameraPosition}
                        viewLevel={viewLevel}
                        setViewLevel={setViewLevel}
                        highestDepth={highestDepth}
                        panelOrder={panelOrder}
                        setPanelOrder={setPanelOrder}
                        setRevealThreshold={setRevealThreshold}
                        scene={scene}
                        setScene={setScene}
                        mapMaximize={mapMaximize}
                        mapResize={mapResize}
                        showBranchLabels={showBranchLabels}
                        setShowBranchLabels={setShowBranchLabels}
                        filesSelectedUserCanAccess={filesSelectedUserCanAccess}
                        inspectorWidth={inspectorWidth}
                        orbitControls={orbitControls}
                        searchResults={searchResults}
                        clickedElement={clickedElement}
                        setClickedElement={setClickedElement}
                        currentTheme={currentTheme}
                        themes={themes}
                        roots={roots}
                        setRoots={setRoots}
                        onlineUsers={onlineUsers}
                        showFiles={showFiles}
                        pathToResultItems={pathToResultItems}
                        updateTree={updateTree}
                        setUpdateTree={setUpdateTree}
                        accessResults={accessResults}
                        activityResults={activityResults}
                        updatedItems={updatedItems}
                        setUpdatedItems={setUpdatedItems}
                        overview={overview}
                        setOverview={setOverview}
                        home={home}
                        setHome={setHome}
                        airTableData={airTableData}
                    />
                </>
            }
            <Background
                backgroundPosition={backgroundPosition}
                settings={settings}
                currentTheme={currentTheme}
                themes={themes}
            />
        </>
    );
}

export default Filetree;