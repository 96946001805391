import React, { useEffect, useState, useRef, useCallback } from 'react';
import { searchableValuesConfig, pathListConfig, dataConfig, organiseDataBy, calculatePath } from '../Utils/Utils.js';

export default function Socket(props) {

    const {
        data,
        setData,
        settings,
        setSettings,
        setSocketStatus,
        account,
        setAccount,
        socketCommand,
        setSocketCommand,
        setAvailableDataRooms,
        setAvailableDataRoomGroups,
        selectedDataRoom,
        setSelectedDataRoom,
        currentDataRoom,
        loading,
        setLoading,
        setSearchableValues,
        setCurrentDataRoom,
        highestDepth,
        setHighestDepth,
        onlineUsers,
        setOnlineUsers,
        allUsers,
        setAllUsers,
        revealThreshold,
        setRevealThreshold,
        setUpdateTree,
        setUpdaterStatus,
        roots,
        setNumOfMatches,
        setSearchResults,
        setPathToResultItems,
        setCurrentAccessActivitySearch,
        setAccessResults,
        setActivityResults,
        setUpdatedItems,
        mode,
        home,
        airTableData,
        setAirTableData
    } = props;

    const ws = useRef(null);

    const [connectionEstablished, setConnectionEstablished] = useState(false)

    const connect = (reconnect) => {

        // Socket saved in ref
        ws.current = new WebSocket(mode === 'dev' ? 'wss://file-os.com:8082' : 'wss://rvrsource.com:8082')
        ws.current.onopen = (e) => {

            console.log('connection open', e)
            setSocketStatus(e.type)
            setConnectionEstablished(true)

            if (reconnect) {

                let id = sessionStorage.getItem("sessionId")
                let sessionDR = JSON.parse(sessionStorage.getItem("datarooms"))
                ws.current.send(JSON.stringify({ "type": "autoReconnect", "sessionId": id, "Datarooms": sessionDR ? sessionDR : [] }))
                // let id = sessionStorage.getItem("sessionId")
                // ws.current.send(JSON.stringify({ type: "firstConnect", sessionId: account.sessionId }))

            } else {

                ws.current.send(JSON.stringify({ type: "firstConnect", sessionId: account.sessionId }))
                sessionStorage.setItem("sessionId", account.sessionId)
                // setTimeout(() => {
                //     ws.current.close()
                // }, 10000)

            }

        };


        ws.current.onclose = (e) => {
            console.log('connection closed', e)
            setSocketStatus(e.type)
            setConnectionEstablished(false)
            setTimeout(() => connect(true), 1000)
        };

        ws.current.onerror = (err) => {
            console.log(err);
        };

    }


    // SOCKET SETUP ###############################################################################
    // Set websocket url
    useEffect(() => {

        return () => {
            ws.current.close()
        };

    }, []);

    // Once user has logged in get all available data rooms
    useEffect(() => {

        if (account) {

            connect()

        };

    }, [account]);

    // If user goes back to home remove all session storage data rooms
    useEffect(() => {

        if (home) {

            sessionStorage.removeItem("datarooms")

        };

    }, [home]);

    // SOCKET COMMANDS ############################################################################
    // Handle socket commands from front end
    useEffect(() => {

        if (connectionEstablished && socketCommand) {

            const {
                type,
                data
            } = socketCommand;

            // REQUEST DATA ROOM ========================================================
            // Request data for specific data room
            if (type === 'dataRoomRequest') {

                console.log('data room request', data);
                setLoading(true);
                ws.current.send(JSON.stringify({ type: "dataRoomRequest", dataRoomId: data }));
                ws.current.send(JSON.stringify({ type: "requestProjectInfo", DRid: data }));

            };

            // REQUEST DATA ROOM GROUPS ======================================================
            // Request various groups of data rooms
            if (type === 'requestGroups') {

                console.log('request data room groups');
                ws.current.send(JSON.stringify({ type: "requestGroups" }));

            };

            // DISCONNECT FROM DATA ROOM ================================================
            if (type === 'disconnectFromDR') {

                console.log('disconnect from data room');
                ws.current.send(JSON.stringify({ type: "disconnectFromDR", dataRoomId: data }));
                let sessionDR = JSON.parse(sessionStorage.getItem("datarooms"))
                sessionDR = sessionDR.filter(elem => elem !== data)
                sessionStorage.setItem("datarooms", JSON.stringify(sessionDR))

            };

            // REQUEST USER ZONE ========================================================
            // Request all users who have acces to data room
            if (type === 'requestDRUserZone') {

                console.log('request user zone');
                ws.current.send(JSON.stringify({ type: "requestDRUserZone", dataRoomId: data }));

            };

            // REQUEST USER ACCESS ======================================================
            // Request the access info for specific user
            if (type === 'requestUserAccesses') {

                console.log('request user access');
                ws.current.send(JSON.stringify({ type: "requestUserAccesses", userId: data.user, dataRoomId: data.dr }));

            };

            // REQUEST USER ACTIVITY ======================================================
            // Request the recent activity of specific user
            if (type === 'requestUserActivity') {

                console.log('request user activity');
                ws.current.send(JSON.stringify({ type: "requestUserActivity", userId: data.user, dataRoomId: data.dr }));

            };

            // REQUEST DATA ROOM POPULATION ======================================================
            // Request the users currently in the data room
            if (type === 'requestDRPopulation') {

                console.log('request data room population');
                ws.current.send(JSON.stringify({ type: "requestDRPopulation", dataRoomId: data }));

            };

            // REQUEST UPDATER STATUS ======================================================
            // Request the updater statuses of all data rooms
            if (type === 'requestUpdaterStatus') {

                console.log('request updater status');
                ws.current.send(JSON.stringify({ type: "requestUpdaterStatus" }));

            };

            // REQUEST KEYWORD RESULTS ======================================================
            // Request the updater statuses of all data rooms
            if (type === 'requestKeyWordResults') {

                console.log('request keyword results');
                ws.current.send(JSON.stringify({ type: "requestKeyWordResults", DRid: data.dr, token: "RVR1337", keyword: data.keyword }));

            };

            setSocketCommand(null);

        };

    }, [socketCommand]);

    // SOCKET RESPONSES ###########################################################################
    // Once websocket is set add listeners to handle socket emissions from back end
    useEffect(() => {

        if (!connectionEstablished) return;

        ws.current.onmessage = (e) => {
            const resp = JSON.parse(e.data);

            let { message, type, value } = resp;

            // FIRST CONNECT ============================================================
            if (type === "firstConnect") {
                console.log(type, message, value);

                if (value) {
                    if (Object.keys(data).length) {
                        // let id = sessionStorage.getItem("sessionId")
                        // let sessionDR = JSON.parse(sessionStorage.getItem("datarooms"))
                        // ws.current.send(JSON.stringify({ "type": "autoReconnect", "sessionId": id, "Datarooms": sessionDR }))
                        // for (const elem of Object.keys(data)) {
                        //     ws.current.send(JSON.stringify({ type: "dataRoomRequest", dataRoomId: elem }));
                        // }
                    } else {
                        ws.current.send(JSON.stringify({ type: "availableDataRooms" }));
                        ws.current.send(JSON.stringify({ type: "requestGroups" }));
                    }
                }
            }

            // AUTO RECONNECT ============================================================
            if (type === "autoReconnect") {
                console.log(type);
                console.log(message);
                console.log(value);

                // if (value) {
                //     ws.current.send(JSON.stringify({ type: "availableDataRooms" }));
                //     ws.current.send(JSON.stringify({ type: "requestGroups" }));
                // }
            }

            // AVAILABLE DATA ROOMS =====================================================
            if (type === "availableDataRooms") {
                // console.log(value);
                // console.log(message);
                if (value) {
                    setAvailableDataRooms(Object.values(value));
                    setSelectedDataRoom(Object.values(value)[0]);
                }
            }

            // REQUEST DATA ROOM GROUPS =====================================================
            if (type === "requestGroups") {
                // console.log(value);
                // console.log(message);
                if (value) setAvailableDataRoomGroups(value);
            }

            // REQUEST DATA ROOM ========================================================
            if (type === "dataRoomRequest") {
                console.log('data room request value', value)
                console.log(message)

                if (value) {
                    let sV = searchableValuesConfig(value)
                    setSearchableValues(sV)

                    let settingsClone = { ...settings }
                    setSettings(pathListConfig(settingsClone, sV))

                    let revealThresholdClone = { ...revealThreshold }
                    revealThresholdClone[selectedDataRoom] = 1
                    setRevealThreshold(revealThresholdClone)

                    let dataClone = { ...data }
                    let highestDepthClone = { ...highestDepth }
                    let cDR = message.replace('here is the data for', '')
                    const { dc, hd } = dataConfig(
                        dataClone,
                        highestDepthClone,
                        cDR,
                        value
                    )
                    setData(dc)
                    setHighestDepth(hd)
                    setCurrentDataRoom(cDR)
                    setLoading(false)
                    sessionStorage.setItem("datarooms", JSON.stringify(Object.keys(dc)));
                }
            }

            // REQUEST AIR TABLE DATA
            if (type === "requestProjectInfo") {
                console.log(type)
                console.log(value)
                console.log(message)

                let airTableDataClone = {...airTableData}
                airTableDataClone[message] = value
                setAirTableData(airTableDataClone)

            }

            // RETURN USER ZONE ========================================================
            // value = array of users with access to data room
            // message = name of data room
            if (type === "returnDRUserZone") {

                console.log(type)
                console.log(value)
                console.log(message)

                // let allUsersClone = { ...allUsers }

                // allUsersClone[message] = {};
                // value.forEach(elem => {
                //     allUsersClone[message][elem] = {};
                // });
                // console.log('allUsers', allUsersClone);

                // setAllUsers(allUsersClone);
                let obj = {}
                for (const elem of value) {
                    obj[elem] = {}
                }
                setAllUsers(obj)
                setLoading(false);

            }

            // REQUEST USER ACCESS ======================================================
            // value = object containing levels of access and the files/folders at that level
            // messgae = [user email, name of data room]
            if (type === "requestUserAccesses") {

                console.log(type);
                console.log(value);
                console.log(message);

                let allUsersClone = { ...allUsers };
                allUsersClone[message[0]].access = value;

                setAllUsers(allUsersClone);

                // handleAccessClick(user);
                setLoading(false);

            }

            // REQUEST USER ACTIVITY ======================================================
            // value = object containing types of activity and the files/folders associated with that activity
            // messgae = [user email, name of data room]
            if (type === "requestUserActivity") {

                console.log(type);
                console.log(value);
                console.log(message);

                let allUsersClone = { ...allUsers };
                allUsersClone[message[0]].activity = value;

                setAllUsers(allUsersClone);

                // handleAccessClick(user);
                // setLoading(false);

            }

            // DATA ROOM POPULATION =====================================================
            // When a user joins/leaves a data room this shows the number of users in that data room
            // value = data room id
            // message = ids of users in data room
            if (type === "dataRoomPopulation") {
                console.log(type);
                console.log(value);
                console.log(message);

                let usersInDataRoomsClone = { ...onlineUsers };
                usersInDataRoomsClone[value] = message;
                setOnlineUsers(usersInDataRoomsClone);
                // setUpdateTree(true);
            }

            // DATA ROOM LEAVE EVENT ====================================================
            // When a user leaves a data room this shows which user has left
            // value = user id
            // message '{user id} has left'
            if (type === "dataRoomLeaveEvent") {
                console.log(type);
                console.log(value);
                console.log(message);

                // let usersInDataRoomsClone = { ...onlineUsers };
                // usersInDataRoomsClone[message] = usersInDataRoomsClone[message].filter(user => user !=== value);
                // setOnlineUsers(usersInDataRoomsClone);
            }

            // REQUEST UPDATER STATUS ====================================================
            // List of updater statuses for data rooms with updaters
            // value = object containing all data rooms with updaters and their status
            // message 'updaterStatuses'
            if (type === "requestUpdaterStatus") {
                // console.log(type);
                // console.log(value);
                // console.log(message);

                setUpdaterStatus(value);
            }

            // REQUEST UPDATER STATUS ====================================================
            // List of updater statuses for data rooms with updaters
            // value = object containing all data rooms with updaters and their status
            // message 'updaterStatuses'
            if (type === "requestKeyWordResults") {
                console.log(type);
                console.log(value);
                console.log(message);

                let results = []
                for (const key of Object.keys(value)) {
                    results.push(key)
                }

                let pathToResultItems = []
                for (const result of results) {
                    let path = calculatePath(result, data[message], roots[message].ID)
                    pathToResultItems = pathToResultItems.concat(path);
                }

                setNumOfMatches(results.length)
                setSearchResults(results)
                setPathToResultItems(pathToResultItems)
                setCurrentAccessActivitySearch(null)
                setAccessResults([])
                setActivityResults([])
            }

            // UPDATE ===================================================================
            // Updates to the structure of the tree
            // value = data to update
            // message = data room id
            if (type === "update") {
                console.log('type', type);
                console.log('value', value);
                console.log('message', message);

                const {
                    itemsAdded,
                    itemsMoved,
                    itemsMovedFromRename,
                    itemsRemoved,
                    itemsUpdated
                } = value;

                let dataClone = { ...data };
                let highestDepthClone = { ...highestDepth };

                // Removed
                if (itemsRemoved.length) {

                    for (const item of itemsRemoved) {
                        console.log('remove', item);
                        delete dataClone[message][item];
                    };

                };

                // Updated
                if (Object.keys(itemsUpdated).length !== 0) {

                    setUpdatedItems(Object.keys(itemsUpdated))

                    for (const [key, val] of Object.entries(itemsUpdated)) {
                        dataClone[message][key] = val;
                    };

                };

                // Added
                if (itemsAdded.length) {

                    for (const item of itemsAdded) {
                        console.log('added', item);
                    };

                };

                // Moved
                if (itemsMoved.length) {

                    for (const item of itemsMoved) {
                        console.log('moved', item);
                    };

                };

                // Renamed
                if (itemsMovedFromRename.length) {

                    for (const item of itemsMovedFromRename) {
                        console.log('renamed', item);
                    };

                };

                const { dc, hd } = dataConfig(
                    dataClone,
                    highestDepthClone,
                    message,
                    dataClone[message]
                );

                setData(dc);
                setHighestDepth(hd);
                setUpdateTree(true);

            }
        };

    }, [connectionEstablished, selectedDataRoom, data, allUsers, roots]);

    return null;

};