import './OrtusLogo.css';

const OrtusLogo = (props) => {

    const {
        showTitle
    } = props;

    return (
        <div
            id="ortusTechnologyLogo"
            style={{
                position: showTitle ? 'relative' : 'absolute',
                top: showTitle ? '0px' : '20px',
                left: showTitle ? '0px' : '20px'
            }}
        >
            {/* {showTitle &&
                <>
                    <p>Pilot</p>
                    <p id="by">by</p>
                </>
            } */}
            <div>
                <img src="/logos/Ortus-Logo-1.png" />
                <p>Italy</p>
            </div>
        </div>
    )

};

export default OrtusLogo;