import React, { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"
import Search from "./Search/Search";
import Settings from "./Settings/Settings";
import FolderContents from "./FolderContents/FolderContents";
import Users from "./Users/Users";
import Map from "./Map/Map";
import './Inspector.css';
import { a } from 'react-spring';

export default function Inspector(props) {

    const {
        panelOrder,
        inspectorWidth
    } = props;

    const [rearrange, setRearrange] = useState(false);
    const [componentState, setComponentState] = useState({
        contents: {
            columns: 1,
            maximize: true,
            beingDragged: false,
            position: {
                x: 0,
                y: 0
            }
        },
        settings: {
            maximize: true,
            beingDragged: false,
            position: {
                x: 0,
                y: 0
            }
        },
        search: {
            maximize: true,
            beingDragged: false,
            position: {
                x: 0,
                y: 0
            }
        },
        users: {
            loading: false,
            visibleUser: null,
            maximize: true,
            beingDragged: false,
            position: {
                x: 0,
                y: 0
            }
        },
        map: {
            maximize: true,
            beingDragged: false,
            position: {
                x: 0,
                y: 0
            }
        }
    });

    let component;
    let componentProps = {};

    for (const [key, value] of Object.entries(props)) {
        componentProps[key] = value;
    };

    useEffect(() => {

        let dragging = false;

        for (const [key, value] of Object.entries(componentState)) {
            if (value.beingDragged) dragging = true;
        };

        if (dragging) setRearrange(true)
        else setRearrange(false);

    }, [componentState]);

    return (

        <motion.div
            id="inspector"
            layout
            style={{
                width: rearrange ? '100vw' : `${inspectorWidth}vw`,
                overflow: rearrange ? 'visible' : 'scroll'
            }}
        >
            {panelOrder.map(elem => {

                component =
                    elem === 'contents' ? FolderContents :
                        elem === 'settings' ? Settings :
                            elem === 'search' ? Search :
                                elem === 'users' ? Users :
                                    elem === 'map' ? Map :
                                        null;

                if (component) {
                    componentProps.componentState = componentState;
                    componentProps.setComponentState = setComponentState;
                    componentProps.rearrange = rearrange;
                    return React.createElement(component, componentProps)
                };

            })}
        </motion.div>
    )
}
