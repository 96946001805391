import React, { useState } from 'react';
import { InspectorAddRemove } from '..//Utils/Utils.js';
import './PortfolioIcon.css';

export default function PortfolioIcon(props) {

    const {
        setOverview
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className="portfolioIconContainer"
            onClick={() => setOverview(true)}
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
        >
            <div className="portfolioIcon">
                <div className="portfolioIconTree">
                    <div></div>
                    <div></div>
                </div>
                <div className="portfolioIconTree">
                    <div className="portfolioIconBigTree"></div>
                    <div className="portfolioIconBigTree"></div>
                </div>
                <div className="portfolioIconTree">
                    <div></div>
                    <div></div>
                </div>
            </div>
            {showLabel &&
                <p className="label">portfolio</p>
            }
        </div>
    )
}
