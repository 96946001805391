import React, { useEffect, useState, useRef, useCallback, Suspense } from 'react';
import { calculateCameraPosition } from '../../../Utils/Utils.js';
import FolderContentsIcon from '../../../Icons/FolderContentsIcon.js';
import MaximizeIcon from '../../../Icons/MaximizeIcon.js';
import MoveComponentIcon from '../../../Icons/MoveComponentIcon.js';
import './FolderContents.css';

export default function FolderContents(props) {

    const {
        data,
        treeData,
        filesToInspect,
        highlightedPath,
        panelOrder,
        setPanelOrder,
        showFolderContents,
        setShowFolderContents,
        viewLevel,
        searchResults,
        componentState,
        setComponentState,
        inspectorWidth,
        rearrange,
        themes,
        currentTheme,
        clickedElement,
        setClickedElement,
        setCameraPosition,
        currentDataRoom,
        roots,
        camera,
        scene
    } = props

    const [componentPosition, setComponentPosition] = useState(null);

    const componentRef = useRef()
    const filesRef = useRef()
    const pathContainerRef = useRef()

    const handleColumnChange = (e) => {
        e.stopPropagation()
        let componentStateClone = { ...componentState }
        componentStateClone.contents.columns = componentStateClone.contents.columns + 1
        if (componentStateClone.contents.columns > 3) componentStateClone.contents.columns = 1
        setComponentState(componentStateClone)
    }

    const formatFileName = (name, fileType, file, link) => {
        name = name.replace(fileType, '')
        if (name.length >= (file.current.offsetWidth / 7.5)) name = name.substring(0, ((file.current.offsetWidth / 7.5) * componentState.contents.columns) - 3) + '...'
        return name
    }

    const handlePathClick = useCallback((e, ID, link) => {

        if (e.type === 'click') {

            if (clickedElement && clickedElement === ID) {
                setClickedElement(null)
                setCameraPosition(calculateCameraPosition(roots[currentDataRoom], camera))
            } else {
                setClickedElement(ID)
                let elem = scene.getObjectByProperty('ID', ID)
                setCameraPosition(calculateCameraPosition(elem, camera))
            }

        } else if (e.type === 'contextmenu') {

            window.open(link, '_blank').focus()

        }

    })

    const StatusBar = () => {

        const [hover, setHover] = useState(false)

        return (
            <div id="folderContentsStatusBar">
                <MaximizeIcon
                    component={'contents'}
                    componentState={componentState}
                    setComponentState={setComponentState}
                />
                {/* <MoveComponentIcon
                    component={'contents'}
                    componentRef={componentRef}
                    componentState={componentState}
                    setComponentState={setComponentState}
                    setComponentPosition={setComponentPosition}
                /> */}
                <div id="columnsContainer" onClick={(e) => handleColumnChange(e)}>
                    {[...Array(componentState.contents.columns)].map(elem => {
                        return <div key={elem} className="column"></div>
                    })}
                </div>
                <div className="folderContentsItemContainer">
                    <p
                        onMouseOver={() => setHover('depth')}
                        onMouseOut={() => setHover(false)}
                    >
                        {viewLevel}
                    </p>
                    {hover === 'depth' &&
                        <p className="label">depth</p>
                    }
                </div>
                <div className="folderContentsItemContainer">
                    <p
                        onMouseOver={() => setHover('children')}
                        onMouseOut={() => setHover(false)}
                    >
                        {filesToInspect.length}
                    </p>
                    {hover === 'children' &&
                        <p className="label">files</p>
                    }
                </div>
            </div>
        )

    }

    const File = (props) => {

        const {
            elem,
            elemInfo
        } = props

        const [hover, setHover] = useState(false)
        const [link, setLink] = useState(null)

        const fileRef = useRef()
        const linkRef = useRef()

        useEffect(() => {
            setLink(formatFileName(elemInfo.name, elemInfo.fileType, fileRef, linkRef))
        }, [])

        return (
            <div
                key={elem}
                ref={fileRef}
                className={searchResults.includes(elem) ? "fileSearchResult" : "file"}
                style={{
                    height: `${(componentState.contents.columns * 30) + 5}px`,
                }}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
            >
                <div>
                    <div className="fileIcon"></div>
                    <p className='fileType'>{elemInfo.fileType.replace('.', '')}</p>
                </div>
                {componentState.contents.columns &&
                    <a
                        ref={linkRef}
                        className="fileLink"
                        href={elemInfo.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {link}
                    </a>
                }
                {hover &&
                    <div className="fileNameLabel">{elemInfo.name}</div>
                }
            </div>
        )

    }

    useEffect(() => {
        let componentStateClone = { ...componentState }
        if (filesToInspect.length) componentStateClone.contents.maximize = true
        else componentStateClone.contents.maximize = false
        setComponentState(componentStateClone)
    }, [filesToInspect])

    return (
        <div
            ref={componentRef}
            id="folderContents"
            style={{
                position: componentState.contents.beingDragged ? 'absolute' : 'relative',
                top: componentState.contents.beingDragged ? `${componentPosition.y}px` : '0px',
                left: componentState.contents.beingDragged ? `${componentPosition.x}px` : '0px',
                marginTop: componentState.contents.beingDragged && '0px',
                width: rearrange ? `calc(${inspectorWidth}vw - 24px)` : `calc(100% - 24px)`,
                height: componentState.contents.maximize ? '500px' : (pathContainerRef.current ? pathContainerRef.current.offsetHeight + 50 : 71) + 'px'
            }}
            onClick={(e) => { e.stopPropagation() }}
        >

            <FolderContentsIcon
                inMenu={false}
                clickable={true}
                panelOrder={panelOrder}
                setPanelOrder={setPanelOrder}
                showFolderContents={showFolderContents}
                setShowFolderContents={setShowFolderContents}
            />

            <StatusBar />

            <div
                id="folderContentsInner"
            >
                <div
                    id="files"
                    style={{
                        display: componentState.contents.maximize ? 'grid' : 'none',
                        gridTemplateColumns: `repeat(${componentState.contents.columns}, 1fr)`
                    }}
                    ref={filesRef}
                >
                    {filesToInspect.map(elem => {

                        if (!treeData || !treeData[elem]) return;

                        return (
                            <File
                                elem={elem}
                                elemInfo={treeData[elem]}
                            />
                        )

                    })}
                </div>
                <div
                    id="path"
                    ref={pathContainerRef}
                >
                    {highlightedPath && highlightedPath.slice(0).reverse().map((elem, i, arr) => {

                        if (!treeData || !treeData[elem]) return;

                        let item = treeData[elem];

                        return (
                            <div key={elem + i} className="pathElement">
                                <div className="pathElementInner">
                                    <p
                                        className="link"
                                        onClick={(e) => handlePathClick(e, elem, item.link)}
                                        onContextMenu={(e) => handlePathClick(e, elem, item.link)}
                                    >
                                        {item.name}
                                    </p>
                                    {/* <a href={item.link} target="_blank" rel="noreferrer">{item.name}</a> */}
                                    <p>{i === arr.length - 1 ? '' : '>'}</p>
                                </div>
                            </div>
                        )

                    })}
                </div>
            </div>

        </div>
    )
}
