import * as THREE from 'three';

// Sort list of data rooms
export function sortDataRooms(type, dataClone) {

    if (type === 'numEntDesc') {

        // Sorted by number of entries in descending order
        dataClone = Object.fromEntries(
            Object.entries(dataClone).sort(([, a], [, b]) => Object.keys(b).length - Object.keys(a).length)
        );

    }

    return dataClone;

};

// Calculate the zone that the element exists in
export function calculateZone(treeData, id) {

    let name;

    const recur = (id) => {

        let parent = treeData[id].parent

        // error here
        if (treeData[parent].depth === 1) name = treeData[parent].name
        else recur(parent);

    };

    recur(id);

    return name;

};

// Calculates categories available in search
export function searchableValuesConfig(result) {

    let sV = {};
    for (const [key, val] of Object.entries(result)) {

        for (const [keyX, valX] of Object.entries(val)) {
            if (keyX.includes('%')) {
                let str = keyX;
                str = str.replace('%', '');
                val[str] = val[keyX];
                delete val[keyX];
            } else {
                if (!sV[keyX]) {
                    let obj;
                    if (typeof valX === 'number') {
                        obj = {
                            type: typeof valX,
                            min: valX,
                            max: valX,
                            step: 1,
                            visibleInPathList: ['size', 'depth'].includes(keyX) ? true : false
                        };
                    } else {
                        obj = {
                            type: typeof valX,
                            visibleInPathList: ['name'].includes(keyX) ? true : false
                        };
                    };
                    sV[keyX] = obj;
                } else if (typeof valX === 'number') {
                    sV[keyX].min = valX < sV[keyX].min ? valX : sV[keyX].min;
                    sV[keyX].max = valX > sV[keyX].max ? valX : sV[keyX].max;
                    sV[keyX].step = Math.floor(sV[keyX].max - sV[keyX].min > 10 ? (sV[keyX].max / 10) : 1);
                };
            };
        };

    };

    let obj = {type: 'string', visibleInPathList: false}
    sV.content = obj

    return sV
};

// Adds available search cetgories to settings panel (may be redundant soon)
export function pathListConfig(settingsClone, sV) {

    for (const [key, value] of Object.entries(sV)) {
        settingsClone.information.path.options.push(key);
        if (value.visibleInPathList) settingsClone.information.path.selected.push(key);
    };

    return settingsClone;

};

// Format data object
export function dataConfig(dataClone, highestDepthClone, dataRoom, value) {

    for (const [key, val] of Object.entries(value)) {

        // Calculate the direct children of the element
        // value = calculateChildren(value, val, key);

        // Calculate the zone of the element
        if (val.depth === 0) value[key].zone = 'root'
        else if (val.depth === 1) value[key].zone = val.name
        else value[key].zone = calculateZone(value, key);

        // Calculate highest depth of the tree
        if (!highestDepthClone[dataRoom]) highestDepthClone[dataRoom] = 0;
        highestDepthClone[dataRoom] = val.depth > highestDepthClone[dataRoom] ? val.depth : highestDepthClone[dataRoom];

    };

    // Adds data room to list of all data rooms
    if (!dataClone) dataClone = {};
    dataClone[dataRoom] = value;

    return { dc: sortDataRooms('numEntDesc', dataClone), hd: highestDepthClone };

};

// Calculates the position to move the camera to based on the size/position of object provided
export function calculateCameraPosition(object, camera) {

    let box = new THREE.Box3().setFromObject(object);

    let size = new THREE.Vector3();
    box.getSize(size);
    let center = new THREE.Vector3();
    box.getCenter(center);

    let fitOffset = size.x > size.y ? 0.7 : 1.1;
    let maxSize = Math.max(size.x, size.y, size.z);
    let fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
    let fitWidthDistance = fitHeightDistance / camera.aspect;

    let distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    return { x: center.x, y: center.y, z: box.max.z + distance, d: 1 };

};

// Calculates the path to given element 
export function calculatePath(id, data, target) {

    let path = [];
    const recur = (id, data) => {
        path.push(id);
        if (id !== target && data[id].depth !== 0) recur(data[id].parent, data);
    };

    recur(id, data);

    return path;

};

// Calcualtes the direct children of an element
export function calculateChildren(data, item, id) {

    if (!data[item.parent].children) data[item.parent].children = [];
    if (item.parent !== id) {
        data[item.parent].children.push(id);
    };

    return data;

};

// Organises the data by a certain perameter
export function organiseDataBy(type, data) {

    let organisedData = {};

    for (const [key, value] of Object.entries(data)) {

        if (value.type !== 'struct') {
            if (!organisedData[value[type]]) organisedData[value[type]] = {};
            organisedData[value[type]][key] = value;
        };

    };

    return organisedData;

};

// Add or remove items from the inspector/sidebar
export function InspectorAddRemove(panelOrder, component, auto) {

    if (panelOrder.includes(component)) panelOrder = panelOrder.filter(elem => elem !== component)
    else panelOrder.unshift(component);

    // console.log('panel order', panelOrder);
    return panelOrder;

};

// Generates a random whole number
export function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Generates a random non-whole number
export function randomNonInt(val) {
    return Math.random() * val;
};