import React, { useState } from 'react';
import './BugIcon.css';

export default function BugIcon(props) {

    const {

    } = props;

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div
            className="bugIconContainer"
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
            onClick={() => window.open('https://forms.gle/thdNB2Y6GTQRbvcn7', '_blank').focus()}
        >
            <div className="bugIcon">
                <div className="bugHead"></div>
                <div className="bugBody"></div>
                <div className="bugLegs">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            {showLabel &&
                <p className="label">bugs</p>
            }
        </div>
    )
}
