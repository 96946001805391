import React, { useEffect, useState, useRef } from 'react';
import { Canvas, useFrame, useThree, extend } from '@react-three/fiber';
import { Sky, PerspectiveCamera, Effects, BakeShadows, Sparkles, Text, CycleRaycast } from '@react-three/drei';
import { EffectComposer, Selection, Outline } from '@react-three/postprocessing';
import { useMotionValue } from "framer-motion"
import { motion, MotionCanvas, LayoutCamera } from "framer-motion-3d"
import * as THREE from 'three';
import { calculateCameraPosition, calculatePath, organiseDataBy } from '../Utils/Utils.js';
import { gsap } from "gsap";
import { Tree } from './Elements/Tree';
import { Ground } from './Elements/Ground';
import { dataConfig } from '../Utils/Utils.js';
import { InspectorAddRemove } from '..//Utils/Utils.js';

import './MainVisual.css';


export default function MainVisual(props) {

    // Props ============================================================================

    const {
        data,
        setData,
        currentDataRoom,
        setCurrentDataRoom,
        dashboardVisible,
        setDashboardVisible,
        viewLevel,
        setViewLevel,
        highestDepth,
        revealThreshold,
        highlightedPath,
        setHighlightedPath,
        setCamera,
        cameraPosition,
        setCameraPosition,
        panelOrder,
        setPanelOrder,
        inspectorWidth,
        setFilesToInspect,
        showBranchLabels,
        searchResults,
        clickedElement,
        setClickedElement,
        scene,
        setScene,
        currentTheme,
        themes,
        roots,
        setRoots,
        onlineUsers,
        showFiles,
        pathToResultItems,
        updateTree,
        setUpdateTree,
        accessResults,
        activityResults,
        updatedItems,
        setUpdatedItems,
        overview,
        setOverview,
        home,
        setHome
    } = props;


    // State ============================================================================

    const [init, setInit] = useState(false)
    const [currentZone, setCurrentZone] = useState(null)
    const [dataOrganisedByZone, setDataOrganisedByZone] = useState(null)
    const [dataOrganisedByDepth, setDataOrganisedByDepth] = useState(null)
    const [raycasterIntersectedObjects, setRaycasterIntersectedObjects] = useState([])
    const [pointerDown, setPointerDown] = useState(false)
    const [cX, setCX] = useState(null)
    const [cY, setCY] = useState(null)

    const cameraRef = useRef()
    const mapCameraRef = useRef()
    const forestRef = useRef()


    // Functions ========================================================================

    const Overview = () => {

        if (overview && Object.keys(data).length > 1) {
            let overviewPos = calculateCameraPosition(forestRef.current, cameraRef.current);
            overviewPos.d = 1;
            setCameraPosition(overviewPos);
            setCurrentDataRoom(null);
            setViewLevel(-1);
            setClickedElement(null);
            setHighlightedPath([]);
            setOverview(false);
        } else {
            setOverview(false);
        };

    }

    const CameraExport = () => {

        let { scene } = useThree()

        useFrame(() => {

            setScene(scene)
            setCamera(cameraRef.current)

        })

        return null;
    }

    const handleWheel = (e) => {

        let { x, y, z } = cameraPosition

        if (e.deltaY < 0) z = z - (z * 0.05)
        else if (e.deltaY > 0) z = z + (z * 0.05)

        setCameraPosition({ x, y, z, d: 0 })

    }

    const handleBackgroundClick = (e) => {

        if (e.type === 'click') {

            if (e.target.nodeName !== 'P' && viewLevel > 0) {
                setClickedElement(null)
                setCameraPosition(calculateCameraPosition(roots[currentDataRoom], cameraRef.current))
            }

        }

    }

    const handleBackgroundPointerDown = (e) => {

        if (e.button === 2 && !raycasterIntersectedObjects.length && e.target.nodeName !== 'P') {
            setCX(e.clientX)
            setCY(e.clientY)
            setPointerDown(true)
        }

    }

    const handleBackgroundPointerUp = (e) => {

        if (pointerDown) setPointerDown(false)

    }

    const handleBackgroundPointerMove = (e) => {

        if (pointerDown && cX && cY) {

            let { x, y, z } = cameraPosition

            console.log(z);

            if (e.clientX > cX) {
                x = x - ((z + 1) * 0.002)
            } else if (e.clientX < cX) {
                x = x + ((z + 1) * 0.002)
            }

            if (e.clientY > cY) {
                y = y + ((z + 1) * 0.002)
            } else if (e.clientY < cY) {
                y = y - ((z + 1) * 0.002)
            }

            setCameraPosition({ x, y, z, d: 0 })
            setCX(e.clientX)
            setCY(e.clientY)

        }

    }


    // Effects ==========================================================================

    useEffect(() => {
        if (data) {
            setInit(true);
        };
    }, [data])

    useEffect(() => {

        if (currentDataRoom) {
            setHighlightedPath([]);
            setCurrentZone(null);
            setViewLevel(0);
            setFilesToInspect([]);
            if (roots[currentDataRoom]) {
                let item = scene.getObjectByProperty('ID', roots[currentDataRoom].ID)
                setCameraPosition(calculateCameraPosition(item, cameraRef.current))
            }
            if (home) {
                console.log('yeet')
                setHome(false)
            }
            if (!dashboardVisible) setDashboardVisible(true)
        }

    }, [currentDataRoom, roots])

    useEffect(() => {

        if (clickedElement) {

            let path = calculatePath(clickedElement, data[currentDataRoom], roots[currentDataRoom].ID);
            setHighlightedPath(path);

            setCurrentZone(data[currentDataRoom][clickedElement].zone);

            if (data[currentDataRoom][clickedElement].nonFolderDescendants.length) {
                let files = data[currentDataRoom][clickedElement].nonFolderDescendants;
                // let files = treeData[clickedElement].nonFolderDescendants.filter(file => treeData[file].type === 'file');
                setFilesToInspect(files);
                if (!panelOrder.includes('contents')) setPanelOrder(InspectorAddRemove([...panelOrder], 'contents'));
            } else {
                setFilesToInspect([]);
            };

            setViewLevel(data[currentDataRoom][clickedElement].depth);
        } else {
            setHighlightedPath([])
            setCurrentZone(null)
            setViewLevel(0)
            setFilesToInspect([])
        };

    }, [clickedElement])

    useEffect(() => {

        // console.log('current zone', currentZone);

    }, [currentZone])

    useEffect(() => {
        if (updateTree) {
            setTimeout(() => {
                setUpdateTree(false)
            }, 1)
        };
    }, [updateTree])



    // Render ===========================================================================

    return (
        <div
            id="visual"
            onWheel={(e) => handleWheel(e)}
        >
            <Canvas
                onPointerMissed={(e) => handleBackgroundClick(e)}
                onPointerDown={(e) => handleBackgroundPointerDown(e)}
                onPointerUp={(e) => handleBackgroundPointerUp(e)}
                onPointerMove={(e) => handleBackgroundPointerMove(e)}
            >
                <color attach="background" args={[themes[currentTheme].background]} />
                <fog attach="fog" args={[themes[currentTheme].background, 0, 700]} />
                <motion.group
                    animate={{
                        x: cameraPosition.x,
                        y: cameraPosition.y,
                        z: cameraPosition.z,
                    }}
                    transition={{
                        type: "spring",
                        bounce: 0.1,
                        duration: cameraPosition.d
                    }}
                >
                    <PerspectiveCamera
                        makeDefault
                        ref={cameraRef}
                    />
                </motion.group>
                <CameraExport />
                <Overview
                    data={data}
                    overview={overview}
                />
                <ambientLight />
                <pointLight position={[10, 50, 50]} />
                <CycleRaycast
                    preventDefault={false}
                    onChanged={(objects) => setRaycasterIntersectedObjects(objects)}
                />
                {init &&
                    <Selection>
                        <EffectComposer multisampling={8} autoClear={false}>
                            <Outline visibleEdgeColor="white" hiddenEdgeColor="white" edgeStrength={50} width={3000} />
                        </EffectComposer>
                        <group
                            ref={forestRef}
                        >
                            {Object.entries(data).map(([key, value], i, arr) => {

                                return (

                                    <Tree
                                        key={key}
                                        data={data}
                                        name={key}
                                        index={i}
                                        currentDataRoom={currentDataRoom}
                                        setCurrentDataRoom={setCurrentDataRoom}
                                        treeData={value}
                                        viewLevel={viewLevel}
                                        setViewLevel={setViewLevel}
                                        highestDepth={highestDepth}
                                        revealThreshold={revealThreshold}
                                        currentZone={currentZone}
                                        clickedElement={clickedElement}
                                        setClickedElement={setClickedElement}
                                        highlightedPath={highlightedPath}
                                        cameraPosition={cameraPosition}
                                        setCameraPosition={setCameraPosition}
                                        cameraRef={cameraRef}
                                        panelOrder={panelOrder}
                                        inspectorWidth={inspectorWidth}
                                        setDashboardVisible={setDashboardVisible}
                                        showBranchLabels={showBranchLabels}
                                        searchResults={searchResults}
                                        currentTheme={currentTheme}
                                        themes={themes}
                                        roots={roots}
                                        setRoots={setRoots}
                                        onlineUsers={onlineUsers}
                                        showFiles={showFiles}
                                        pathToResultItems={pathToResultItems}
                                        updateTree={updateTree}
                                        accessResults={accessResults}
                                        activityResults={activityResults}
                                        updatedItems={updatedItems}
                                        setUpdatedItems={setUpdatedItems}
                                    />

                                )

                            })}
                        </group>
                        <Ground
                            currentTheme={currentTheme}
                            themes={themes}
                        />
                    </Selection>
                }
            </Canvas>
        </div>
    )
}