import React, { useState } from 'react';
import './StarredIcon.css';

export default function StarredIcon(props) {

    const {
        currentSearch,
        setCurrentSearch,
        setSearchResults,
        setPathToResultItems,
        setNumOfMatches
    } = props;

    const [showLabel, setShowLabel] = useState(false);

    const handleCLick = () => {
        if (currentSearch === '*') {
            setCurrentSearch(null);
            setSearchResults([]);
            setPathToResultItems([]);
            setNumOfMatches(null);
        } else {
            setNumOfMatches(null);
            setCurrentSearch('*');
        }
    };

    return (
        <div
            className="starredIconContainerMenu"
            onMouseOver={() => setShowLabel(true)}
            onMouseOut={() => setShowLabel(false)}
            onClick={() => handleCLick()}
        >
            <div className="starredIcon">
                *
            </div>
            {showLabel &&
                <p className="label">starred</p>
            }
        </div>
    )
}